<footer class="footer py-3">
  <div class="container"
      fxFlex
      fxLayout="column"
      fxLayout.lt-md="column"
      fxLayoutGap="10px">

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="lefet left" fxLayoutGap="0">
      <div fxLayout="row wrap" fxFlex="100%" fxFlex.gt-xs="50%">
        <div fxFlex="nogrow">
          <img src="assets/images/regione-calabria-logo-footer.png" alt="Logo Regione Calabria">
        </div>
        <div fxFlex="nogrow">&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div fxFlex="nogrow">
          <p class="Footer-siteName">Regione Calabria</p>
          <small>SISTEMA GESTIONALE DEI PAGAMENTI ELETTRONICI DELLE ENTRATE DELLA PA</small>
        </div>
      </div>

      <div fxFlex="grow" ngClass="text-right my-0" ngClass.xs="text-left my-3" fxFlex="100%" fxFlex.gt-xs="50%">
        <img src="assets/images/logo-footer-POR.png" alt="Logo POR">
      </div>  

    </div>

    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0px">
      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="35%" class="mb-3">
        <h2 class="mat-h2">Contatti</h2>
          <address>
            Regione Calabria <br>
            Dipartimento Transizione Digitale ed Attività Strategiche <br>
            Cittadella Regionale - Località Germaneto <br>
            88100 Catanzaro <br>
          </address>
      </div>

      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="40%" class="mb-3 mt-5">
        <!--h2 class="mat-h2">&nbsp;</h2-->
        <address>
          <fa-icon [icon]="iconEnvelope"></fa-icon>&nbsp;
          <a href="mailto:mypay@regione.calabria.it" target="_top" title="E-mail">mypay@regione.calabria.it</a> (NON PEC)<br>
          <fa-icon [icon]="iconPhone"></fa-icon>&nbsp; 0961 856248 - attivo dal lunedi al venerdi <br>dalle ore 9:00 alle ore 18:00<br>
      </address>
      </div>

    </div>

    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0">
      <div fxFlex="100%" fxFlex.gt-sm="35%">
      	<p>© 2023 <a href="http://www.regione.calabria.it" target="_blank" title="Portale Regione Calabria">Regione Calabria</a> - P. Iva 02205340793</p>
      </div>
      <div fxFlex="100%" fxFlex.gt-sm="65%" ngClass="text-right my-0" ngClass.xs="text-left my-3">
        <ul class="footer-list-link">
          <li><a class="ml-0" [routerLink]="" (click)="showAppInfo()">Versione</a></li>
          <li><a class="ml-2" href="#" target="_blank">Accessibilità</a></li>
          <li><a class="ml-2" routerLink="/info/privacy">Privacy</a></li>
        <!--a class="ml-2" [routerLink]="">Privacy</a-->
          <li><a class="ml-2" routerLink="/info/cookie">Informativa Cookie</a></li>
        <!--a class="ml-2" [routerLink]="">Informativa Cookie</a-->
          <li><a class="ml-2" [routerLink]="" (click)="resetCookieConsent()">Rimuovi preferenze cookie</a></li>
        </ul>
      </div>
    </div>

  </div>
  </footer>